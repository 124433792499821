* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background-color: rgb(255, 255, 255);
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-image: "https://switch.com.my/wp-content/uploads/2017/11/iphonex_spacegray.png";
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 10%;
}

#root {
   background-color: white;
}

